export const DEFAULT_CONTRIBUTION = {
  purchase_contribution: null,
  purchase_contribution_loading: false,
  get_contributions_for_a_single_user: null,
  get_contributions_for_a_single_user_loading: false,
  get_all_contributions: null,
  get_all_contributions_pagination: null,
  get_all_contributions_loading: false,
  get_total_contributions: null,
  get_total_contribution_amount: null,
  get_last_contributor: null,
  general_contributions: [],
  get_total_payouts: null,
  get_payers: null,
  get_payers_loading: false,
  get_current_position_of_a_user: null,
  get_current_position_of_a_user_loading: false,
  get_next_inline_for_a_payout: null,
  get_next_inline_for_a_payout_loading: false,
  error: {
    purchase_contribution_error: null,
    get_contributions_for_a_single_user_error: null,
    get_all_contributions_error: null,
    get_payers_error: null,
    get_current_position_of_a_user_error: null,
    get_next_inline_for_a_payout_error: null
  }
}
