import axios from '../../utils/axios'
import { setAlert } from '../alert/actions'
import { Dispatch } from 'redux'
import {
  LOGGED_IN_USER,
  LoggedInUser,
  LOGGED_IN_USER_SUCCESS,
  LoggedInUserSuccess,
  LOGGED_IN_USER_FAILED,
  LoggedInUserFailed,
  GET_USER,
  GetUser,
  GET_USER_SUCCESS,
  GetUserSuccess,
  GET_USER_FAILED,
  GetUserFailed,
  GET_ALL_USERS,
  GetAllUsers,
  GET_ALL_USERS_SUCCESS,
  GetAllUsersSuccess,
  GET_ALL_USERS_FAILED,
  GetAllUsersFailed,
  UPDATE_USER_PROFILE,
  UpdateUserProfile,
  UPDATE_USER_PROFILE_SUCCESS,
  UpdateUserProfileSuccess,
  UPDATE_USER_PROFILE_FAILED,
  UpdateUserProfileFailed,
  DELETE_USER,
  DeleteUser,
  DELETE_USER_SUCCESS,
  DeleteUserSuccess,
  DELETE_USER_FAILED,
  DeleteUserFailed,
  UPLOAD_USER_AVATAR,
  UploadUserAvatar,
  UPLOAD_USER_AVATAR_SUCCESS,
  UploadUserAvatarSuccess,
  UPLOAD_USER_AVATAR_FAILED,
  UploadUserAvatarFailed,
  SEARCH_GET_USER,
  SearchGetUser,
  SEARCH_GET_USER_SUCCESS,
  SearchGetUserSuccess,
  SEARCH_GET_USER_FAILED,
  SearchGetUserFailed,
  SEARCH_USER,
  SearchUser,
  SEARCH_USER_SUCCESS,
  SearchUserSuccess,
  SEARCH_USER_FAILED,
  SearchUserFailed,
  BLOCK_USER,
  BlockUser,
  BLOCK_USER_SUCCESS,
  BlockUserSuccess,
  BLOCK_USER_FAILED,
  BlockUserFailed,
  UPDATE_BANK_DETAILS,
  UpdateBankDetails,
  UPDATE_BANK_DETAILS_SUCCESS,
  UpdateBankDetailsSuccess,
  UPDATE_BANK_DETAILS_FAILED,
  UpdateBankDetailsFailed,
  CHANGE_PASSWORD,
  ChangePassword,
  CHANGE_PASSWORD_SUCCESS,
  ChangePasswordSuccess,
  CHANGE_PASSWORD_FAILED,
  ChangePasswordFailed,
  GET_ALL_INVITEES,
  GetAllInvitees,
  GET_ALL_INVITEES_SUCCESS,
  GetAllInviteesSuccess,
  GET_ALL_INVITEES_FAILED,
  GetAllInviteesFailed,
} from './constants'
// import { UpCircleOutlined } from '@ant-design/icons'

type formDataType = object

// Get Logged In User
export const getLoggedInUser =
  (id: string) =>
  async (
    dispatch: Dispatch<
      LoggedInUser | LoggedInUserSuccess | LoggedInUserFailed | any
    >
  ) => {
    try {
      dispatch({ type: LOGGED_IN_USER })
      const res = await axios.get(`/user/${id}`)
      dispatch({
        type: LOGGED_IN_USER_SUCCESS,
        payload: res
      })
    } catch (err: any) {
      if (err.response) {
        dispatch(setAlert(err.response.data.message, 'warning'))
        dispatch({
          type: LOGGED_IN_USER_FAILED,
          payload: err.response.data.message
        })
      } else {
        dispatch(setAlert(err.message, 'error'))
        dispatch({
          type: LOGGED_IN_USER_FAILED,
          payload: err.message
        })
      }
    }
  }

// Get User
export const getUser =
  (id: string) =>
  async (
    dispatch: Dispatch<GetUser | GetUserSuccess | GetUserFailed | any>
  ) => {
    try {
      dispatch({ type: GET_USER })
      const res = await axios.get(`/user/${id}`)
      dispatch({
        type: GET_USER_SUCCESS,
        payload: res
      })
    } catch (err: any) {
      if (err.response) {
        dispatch(setAlert(err.response.data.message, 'warning'))
        dispatch({
          type: GET_USER_FAILED,
          payload: err.response.data.message
        })
      } else {
        dispatch(setAlert(err.message, 'error'))
        dispatch({
          type: GET_USER_FAILED,
          payload: err.message
        })
      }
    }
  }

// Get All Users
export const getAllUsers =
  (page?: number | undefined, pageSize?: number | undefined, searchValue?: any, query?: any) =>
  async (
    dispatch: Dispatch<
      GetAllUsers | GetAllUsersSuccess | GetAllUsersFailed | any
    >
  ) => {
    try {
      dispatch({ type: GET_ALL_USERS })

      let res: any;

      if (searchValue && query) {
         res = await axios.get(`/user/list?page=${1}&pageSize=${20}&${query}=${searchValue}`)
      } else {
         res = await axios.get(`/user/list?page=${page ?? 1}&pageSize=${pageSize ?? 20}`)
      }

      dispatch({
        type: GET_ALL_USERS_SUCCESS,
        payload: res
      })
    } catch (err: any) {
      if (err.response) {
        dispatch(setAlert(err.response.data.message, 'warning'))
        dispatch({
          type: GET_ALL_USERS_FAILED,
          payload: err.response.data.message
        })
      } else {
        dispatch(setAlert(err.message, 'error'))
        dispatch({
          type: GET_ALL_USERS_FAILED,
          payload: err.message
        })
      }
    }
  }

// Get All Invitees
export const getAllInvitees =
  () =>
    async (
      dispatch: Dispatch<
        GetAllInvitees | GetAllInviteesSuccess | GetAllInviteesFailed | any
      >
    ) => {
      try {
        dispatch({ type: GET_ALL_INVITEES })
        const res = await axios.get(`/user/invitees`)

        dispatch({
          type: GET_ALL_INVITEES_SUCCESS,
          payload: res
        })
      } catch (err: any) {
        if (err.response) {
          dispatch(setAlert(err.response.data.message, 'warning'))
          dispatch({
            type: GET_ALL_INVITEES_FAILED,
            payload: err.response.data.message
          })
        } else {
          dispatch(setAlert(err.message, 'error'))
          dispatch({
            type: GET_ALL_INVITEES_FAILED,
            payload: err.message
          })
        }
      }
    }

// Update User Profile
export const updateUserProfile =
  (id: string, formData: formDataType) =>
  async (
    dispatch: Dispatch<
      | UpdateUserProfile
      | UpdateUserProfileSuccess
      | UpdateUserProfileFailed
      | any
    >
  ) => {
    try {
      dispatch({ type: UPDATE_USER_PROFILE })
      const res = await axios.put(`/user/update/${id}`, formData)

      dispatch(setAlert(res.data.message, 'success'))
      dispatch({
        type: UPDATE_USER_PROFILE_SUCCESS,
        payload: res
      })
    } catch (err: any) {
      if (err.response) {
        dispatch(setAlert(err.response.data.message, 'warning'))
        dispatch({
          type: UPDATE_USER_PROFILE_FAILED,
          payload: err.response.data.message
        })
      } else {
        dispatch(setAlert(err.message, 'error'))
        dispatch({
          type: UPDATE_USER_PROFILE_FAILED,
          payload: err.message
        })
      }
    }
  }

// Delete User
export const deleteUser =
  (id: string) =>
  async (
    dispatch: Dispatch<DeleteUser | DeleteUserSuccess | DeleteUserFailed | any>
  ) => {
    try {
      dispatch({ type: DELETE_USER })
      const res = await axios.delete(`/user/delete/${id}`)

      dispatch(setAlert(res.data.message, 'success'))
      dispatch({
        type: DELETE_USER_SUCCESS,
        payload: res
      })
    } catch (err: any) {
      if (err.response) {
        dispatch(setAlert(err.response.data.message, 'warning'))
        dispatch({
          type: DELETE_USER_FAILED,
          payload: err.response.data.message
        })
      } else {
        dispatch(setAlert(err.message, 'error'))
        dispatch({
          type: DELETE_USER_FAILED,
          payload: err.message
        })
      }
    }
  }

// Upload User Avatar
export const uploadUserAvatar =
  (id: string, formData: any) =>
  async (
    dispatch: Dispatch<
      UploadUserAvatar | UploadUserAvatarSuccess | UploadUserAvatarFailed | any
    >
  ) => {
    try {
      dispatch({ type: UPLOAD_USER_AVATAR })
      const res = await axios.put(`/user/update/avatar`, formData)

      dispatch(setAlert(res.data.message, 'success'))
      dispatch({
        type: UPLOAD_USER_AVATAR_SUCCESS,
        payload: res
      })
    } catch (err: any) {
      if (err.response) {
        dispatch(setAlert(err.response.data.message, 'warning'))
        dispatch({
          type: UPLOAD_USER_AVATAR_FAILED,
          payload: err.response.data.message
        })
      } else {
        dispatch(setAlert(err.message, 'error'))
        dispatch({
          type: UPLOAD_USER_AVATAR_FAILED,
          payload: err.message
        })
      }
    }
  }

// Search Get User
export const searchGetUser =
  (values: any) =>
  async (
    dispatch: Dispatch<
      SearchGetUser | SearchGetUserSuccess | SearchGetUserFailed | any
    >
  ) => {
    try {
      dispatch({ type: SEARCH_GET_USER })
      const res = await axios.get(`/user/?username=${values}`)

      dispatch(setAlert(res.data.message, 'success'))
      dispatch({
        type: SEARCH_GET_USER_SUCCESS,
        payload: res
      })
    } catch (err: any) {
      if (err.response) {
        dispatch(setAlert(err.response.data.message, 'warning'))
        dispatch({
          type: SEARCH_GET_USER_FAILED,
          payload: err.response.data.message
        })
      } else {
        dispatch(setAlert(err.message, 'error'))
        dispatch({
          type: SEARCH_GET_USER_FAILED,
          payload: err.message
        })
      }
    }
  }

// Search User
export const searchUser =
  (values: any) =>
    async (
      dispatch: Dispatch<
        SearchUser | SearchUserSuccess | SearchUserFailed | any
      >
    ) => {
      try {
        dispatch({ type: SEARCH_USER })
        const res = await axios.get(`/user/list?phone=${values}`)

        dispatch(setAlert(res.data.message, 'success'))
        dispatch({
          type: SEARCH_USER_SUCCESS,
          payload: res
        })
      } catch (err: any) {
        if (err.response) {
          dispatch(setAlert(err.response.data.message, 'warning'))
          dispatch({
            type: SEARCH_USER_FAILED,
            payload: err.response.data.message
          })
        } else {
          dispatch(setAlert(err.message, 'error'))
          dispatch({
            type: SEARCH_USER_FAILED,
            payload: err.message
          })
        }
      }
    }

// Update Bank Details
export const updateBankDetails =
  (formData: formDataType, id?: string) =>
  async (
    dispatch: Dispatch<
      | UpdateBankDetails
      | UpdateBankDetailsSuccess
      | UpdateBankDetailsFailed
      | any
    >
  ) => {
    try {
      dispatch({ type: UPDATE_BANK_DETAILS })
      let url = `/user/update/bank-details`
      if (id) url = `/user/update/bank-details/${id}`

      // console.log(url)

      const res = await axios.put(url, formData)

      dispatch(setAlert(res.data.message, 'success'))
      dispatch({
        type: UPDATE_BANK_DETAILS_SUCCESS,
        payload: res
      })
    } catch (err: any) {
      if (err.response) {
        dispatch(setAlert(err.response.data.message, 'warning'))
        dispatch({
          type: UPDATE_BANK_DETAILS_FAILED,
          payload: err.response.data.message
        })
      } else {
        dispatch(setAlert(err.message, 'error'))
        dispatch({
          type: UPDATE_BANK_DETAILS_FAILED,
          payload: err.message
        })
      }
    }
  }

// Block User
export const blockUser =
  (id: string) =>
  async (
    dispatch: Dispatch<BlockUser | BlockUserSuccess | BlockUserFailed | any>
  ) => {
    try {
      dispatch({ type: BLOCK_USER })
      const res = await axios.post(`/user/block/${id}`)

      dispatch(setAlert(res.data.message, 'success'))
      dispatch({
        type: BLOCK_USER_SUCCESS,
        payload: res
      })
    } catch (err: any) {
      if (err.response) {
        dispatch(setAlert(err.response.data.message, 'warning'))
        dispatch({
          type: BLOCK_USER_FAILED,
          payload: err.response.data.message
        })
      } else {
        dispatch(setAlert(err.message, 'error'))
        dispatch({
          type: BLOCK_USER_FAILED,
          payload: err.message
        })
      }
    }
  }

// Change Password
export const changePassword =
  (formData: formDataType, id?: any) =>
  async (
    dispatch: Dispatch<
      ChangePassword | ChangePasswordSuccess | ChangePasswordFailed | any
    >
  ) => {
    try {
      dispatch({ type: CHANGE_PASSWORD })

      const res = await axios.put(`/user/update/password/`, formData)
      dispatch(setAlert(res.data.message, 'success'))
      dispatch({
        type: CHANGE_PASSWORD_SUCCESS,
        payload: res
      })
    } catch (err: any) {
      if (err.response) {
        dispatch(
          setAlert(
            err.response.data.message
              ? err.response.data.message
              : err.response.statusText,
            'warning'
          )
        )
        dispatch({
          type: CHANGE_PASSWORD_FAILED,
          payload: err.response.data.message
            ? err.response.data.message
            : err.response.statusText
        })
      } else {
        dispatch(setAlert(err.message, 'error'))
        dispatch({
          type: CHANGE_PASSWORD_FAILED,
          payload: err.message
        })
      }
    }
  }
